import React, { useState, useEffect } from 'react'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'

interface Props {
	title: string
	url: string
}

export default function SocialShares({ title, url }: Props) {
	const [copied, setCopied] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setCopied(false)
		}, 2000)
	}, [copied])

	return (
		<div className="mt-10">
			<p>
				For any press related inquiries, please contact <a href="mailto:news@americanreading.com">news@americanreading.com</a>
			</p>
			<div className="flex items-center gap-4">
				<div className="flex items-center gap-3">
					<FacebookShareButton url={url} quote={title}>
						<FacebookIcon size={32} round />
					</FacebookShareButton>
					<TwitterShareButton url={url} title={title} via="AmericanReading">
						<TwitterIcon size={32} round />
					</TwitterShareButton>
				</div>
				<div className="flex flex-col items-center  md:flex-row">
					<div className="flex w-full items-center">
						<div className="relative">
							<input readOnly type="text" className="border-gray-200 focus:border-gray-200 focus:outline-none focus:ring-0" value={url} />
						</div>
						<CopyToClipboard onCopy={() => setCopied(true)} text={url}>
							<button className="ml-3 text-gray-400 hover:text-gray-500 focus:outline-none">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
									/>
								</svg>
							</button>
						</CopyToClipboard>
						<span className="block text-sm font-semibold text-green-500 md:text-base" style={{ width: 75 }}>
							{copied ? 'Copied!' : ''}
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}
