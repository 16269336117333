import { INLINES } from '@contentful/rich-text-types'
import { useLocation } from '@reach/router'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import SocialShares from 'components/news/SocialShares'
import { graphql, Link } from 'gatsby'
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import SEO from '../components/news/SEO'
import { H1 } from 'components/typography/Headings'

interface Props {
	data: PostQuery
}

export default function NewsArticleTemplate({ data: postData }: Props) {
	const data = postData.contentfulPost
	const website_url = 'https://americanreading.com'
	const richTextOptions = {
		renderNode: {
			[INLINES.HYPERLINK]: (node: any) => {
				return (
					<a
						href={node.data.uri}
						target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`}
						rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}
					>
						{node.content[0].value.replace('Read Full Article>', 'Read Full Article >')}
					</a>
				)
			}
		}
	}

	const location = useLocation()
	const url = location.href

	return (
		<Layout>
			<SEO data={data} url={url} />
			<Section>
				<Row>
					<Col className="mt-20">
						<article className="prose prose-gray mx-auto lg:prose-lg xl:prose-xl prose-a:text-ab-100 prose-img:rounded-xl prose-img:shadow-lg">
							{data.image && <img src={data.image.file.url} className="w-full" alt={data.title} />}
							<div className="my-4">
								<Link to="/news" className="text-ab-100">
									&#60; Return to News
								</Link>
							</div>
							<div className="mb-4">
								<H1>{data.title}</H1>
								<time dateTime={data.publishedDate} className="mt-4">
									{data.publishedDate}
								</time>
							</div>
							{renderRichText(data.content, richTextOptions)}
							<SocialShares title={data.title} url={url} />
						</article>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

export interface Post {
	title: string
	snippet?: string
	image?: {
		file: {
			url: string
		}
		title: string
	}
	content: RenderRichTextData<ContentfulRichTextGatsbyReference>
	category?: string
	publishedDate: string
	slug: string
}

interface PostQuery {
	contentfulPost: Post
}

export const query = graphql`
	query ($slug: String!) {
		contentfulPost(slug: { eq: $slug }) {
			title
			snippet
			image {
				file {
					url
				}
				title
			}
			content {
				raw
			}
			category
			publishedDate(formatString: "MMMM DD, YYYY")
			slug
		}
	}
`
