import React from 'react'
import { Helmet } from 'react-helmet'
import { Post } from '../../templates/news'

interface Props {
	data: Post
	url: string
}

export default function SEO({ data, url }: Props) {
	return (
		<Helmet title={data.title}>
			<title>{data.title}</title>
			<meta name="description" content={data.snippet ? data.snippet : 'A news article from American Reading Company'} />
			<meta name="image" content={data.image ? data.image.file.url : '/images/default-og-image.png'} />
			<meta property="og:url" content={url} />
			<meta property="og:title" content={data.title} />
			<meta property="og:description" content={data.snippet ? data.snippet : 'A news article from American Reading Company'} />
			<meta property="og:image" content={data.image ? data.image.file.url : '/images/default-og-image.png'} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:creator" content="@AmericanReading" />
			<meta name="twitter:title" content={data.title} />
			<meta name="twitter:description" content={data.snippet ? data.snippet : 'A news article from American Reading Company'} />
			<meta name="twitter:image" content={data.image ? data.image.file.url : '/images/default-og-image.png'} />
		</Helmet>
	)
}
